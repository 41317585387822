import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionProductListing from '../components/sections/product-listing';
import SectionArticlePreview from '../components/sections/article-preview';
import SectionFooter from '../components/sections/footer';

import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { IPageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { endpoints } from '../config/endpoints';

import { IPage } from '../models/page.model';
import { IProduct } from '../models/product.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IProductCategory } from '../models/product-category.model';
import { ISetting } from '../models/setting.model';
import { ISection } from '../models/section.model';
import { TLocale } from '../locale';

import { getNodes } from '../utils/get-nodes';
import { useList } from '../hooks/use-list';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';
import useTranslations from '../hooks/use-translations';

import MainLayout from '../layouts/main-layout';
import ThemeStyle from '../components/atoms/theme-style';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';
import getShopSettings from "../utils/get-shop-settings";

interface IShopProps {
    readonly pageContext: {
        categoryId?: number;
    } & IPageContext;
    readonly data: {
        page: IPage;
        giftFinderPage: Pick<IPage, 'pathname'> | null;
        shopPage: Pick<IPage, 'pathname'>;
        productCategory: IProductCategory | null;
        allThemeSetting: IQueryAllResult<ISetting>;
        allShopSetting: IQueryAllResult<ISetting>;
    };
}

const DEFAULT_PRODUCTS_PER_PAGE = 24;

const Shop: React.FC<IShopProps> = ({ data, pageContext }) => {
    const t = useTranslations('Shop');
    const {
        page,
        productCategory,
        giftFinderPage,
        allThemeSetting,
        allShopSetting,
    } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);
    const { categoryId, breadcrumbs } = pageContext;
    const sections = page.sections.map((section) => {
        return section.type === 'product-listing'
            ? { ...section, settings: { ...section.settings, isMain: true } }
            : section;
    });
    const { mainSectionIndex } = getPageConfigFromSections(sections);
    const additionalParams = useMemo(() => {
        return getAdditionalParams(categoryId, productCategory);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);

    const { perPage, perRow } = getShopSettings(allShopSetting);

    const {
        items,
        filters,
        status,
        initialSearchValue,
        isInitialLoading,
        paginationPaths,
        pagination,
        selectedFilters,
        handleChange,
        sort,
        values,
    } = useList<IProduct>({
        additionalParams,
        endpoint: endpoints.products,
        perPage: perPage ? Number(perPage) : DEFAULT_PRODUCTS_PER_PAGE,
    });

    const sectionsWithData = sections.map((section) => {
        if (section.type === 'product-listing') {
            const title = getListingTitle({
                page,
                productCategory,
                categoryId,
                section,
                breadcrumbs,
                searchValue: initialSearchValue,
                t,
            });
            const giftFinderPathname = giftFinderPage?.pathname;
            const sectionButton = section.content.buttons[0];
            const newSectionButton =
                giftFinderPathname && sectionButton
                    ? { ...sectionButton, url: giftFinderPathname }
                    : sectionButton;
            return {
                ...section,
                content: {
                    ...section.content,
                    buttons: [newSectionButton],
                    texts: [title],
                },
                items: {
                    products: items,
                },
                settings: {
                    ...section.settings,
                    filters,
                    sort,
                    values,
                    status,
                    isInitialLoading,
                    paginationPaths,
                    pagination,
                    onChange: handleChange,
                    selectedFilters,
                    perRow: perRow,
                },
            };
        }
        if (section.type === 'article-preview' && categoryId && productCategory) {
            return {
                ...section,
                content: {
                    ...section.content,
                    texts: [
                        productCategory.descriptionTitle || section.content.texts[0] || '',
                        productCategory.caption || section.content.texts[1] || '',
                        productCategory.description || section.content.texts[2] || '',
                    ],
                },
            };
        }
        return section;
    });

    return (
        <MainLayout>
            <ThemeStyle style={customStyle} />
            {sectionsWithData.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

interface IGetListingTitleConfig {
    page: IPage;
    categoryId?: number | null;
    productCategory: IProductCategory | null;
    searchValue: string;
    breadcrumbs: IPageContext['breadcrumbs'];
    section: ISection;
    t: TLocale['Shop'];
}
function getListingTitle({
    page,
    categoryId,
    productCategory,
    searchValue,
    section,
    t,
}: IGetListingTitleConfig) {
    const { type } = page;
    if (productCategory && categoryId) {
        return productCategory.title;
    }
    if (type === 'search-product-listing' && searchValue) {
        return `${t.results} "${searchValue}"`;
    }
    return section.content.texts[0];
}

function getAdditionalParams(categoryId?: number, productCategory?: IProductCategory | null) {
    if (!categoryId || !productCategory || !productCategory.activeFilters) return;
    return {
        [productCategory.activeFilters.paramName]: productCategory.activeFilters.value,
    };
}

export const query = graphql`
    query ($pageId: Int!, $locale: String!, $categoryId: Int) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            pathname
            type
            sections {
                ...sectionFields
            }
        }
        giftFinderPage: page(type: { eq: "gift-finder" }, locale: { eq: $locale }) {
            pathname
        }
        allShopSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
        productCategory(categoryId: { eq: $categoryId }, locale: { eq: $locale }) {
            categoryId
            name
            title
            description
            descriptionTitle
            caption
            activeFilters {
                paramName
                value
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default Shop;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'product-listing': SectionProductListing,
    'article-preview': SectionArticlePreview,
    'footer': SectionFooter,
};